<template>
  <v-card elevation="0">
    <v-card-title class="d-flex align-center justify-space-between black">
      <h3 class="white--text text-h6 mr-1">
        {{ $t('notification_bell.notification') }}
      </h3>
      <c-switch
        :input-value="options.readAtSwitch"
        class="mt-0"
        @change="setReadAtSwitch(!!$event)"
      >
        <template #label>
          <span class="white--text text-body-2">
            {{ $t('notification_bell.show_unread') }}
          </span>
        </template>
      </c-switch>
    </v-card-title>

    <v-card-text
      class="d-flex row align-center justify-end justify-md-space-between ma-0 pa-0 pa-md-3"
    >
      <c-toggle-buttons
        v-if="!isMobile"
        v-model="options.categoryGroups"
        :buttons="buttonsCategoryGroups"
        mandatory
        item-text="name"
        class="pt-4 px-5"
        :button-attrs="{
          color: 'transparent',
          class: 'text-caption black--text border-secondary rounded mr-3'
        }"
        @input="resetOptions()"
      />

      <div v-if="!options.categoryGroups" class="pt-0 pt-4 px-5">
        <a
          class="text-decoration-underline pr-6"
          :class="unreadNotifications === 0 ? 'isDisabled' : 'info--text'"
          @click="readAllNotification()"
        >
          {{ $t('notification_bell.mark_all_as_read') }}
        </a>

        <a
          class="text-decoration-underline"
          :class="notifications.length === 0 ? 'isDisabled' : 'info--text'"
          @click="isShownRemove = true"
        >
          {{ $t('notification_bell.delete_all') }}
        </a>

        <remove-notification-dialog
          :title="$t('notification_bell.confirm_title')"
          :is-shown="isShownRemove"
          @close-dialog="isShownRemove = false"
          @remove-notifications="removeNotifications()"
        />
      </div>

      <div v-if="isMobile" class="full-width">
        <c-select
          v-model="options.categoryGroups"
          :items="buttonsCategoryGroups"
          label-bold
          background-color="secondary"
          hide-details
          item-slot
          item-text="name"
          item-value="value"
          class="pa-4"
          @change="resetOptions()"
        >
          <template #prepend-inner>
            <v-icon size="15" class="mt-1 mr-1">
              {{ selectedIcon }}
            </v-icon>
          </template>

          <template #item="{ item, on }">
            <v-list-item v-on="on">
              <v-icon class="mr-2" size="20">
                {{ item.icon }}
              </v-icon>
              {{ item.name }}
            </v-list-item>
          </template>
        </c-select>
      </div>
    </v-card-text>

    <v-card width="783" class="mx-auto full-height pa-7" elevation="0">
      <div v-if="!isLoading">
        <v-card-text v-if="notifications.length" class="pa-0">
          <v-list v-for="(notification, index) in notifications" :key="index" two-line class="py-0">
            <v-subheader v-if="isShowDate(index)" class="d-flex justify-center">
              {{ dateFormatter(notification.created_at) }}
            </v-subheader>

            <v-divider v-if="isShowDivider(index)" />

            <v-subheader
              v-if="isShowStatus(notification.category?.category_group?.name)"
              inset
              class="d-flex justify-space-between px-0"
            >
              <custom-chip-status
                small
                label
                :settings="approvalStatusTypes"
                :value="notification.data.event"
              />

              <button-read-notification
                v-model="notification.read_at"
                @input="readNotification(notification.id)"
              />
            </v-subheader>

            <v-list-item class="px-0">
              <v-list-item-icon class="mt-7">
                <v-icon>
                  {{
                    compareNotificationIconByEvent(
                      notification.category && notification.category.name
                    )
                  }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ notification.category && notification.category.name }}
                </v-list-item-subtitle>

                <v-list-item-title class="white-space">
                  {{ compareNotificationTextByEvent(notification.data) }}
                  <div v-if="notification.data.event === 'campaign.hasTooltip'">
                    <v-skeleton-loader
                      :loading="tooltipTranslationsAreLoading"
                      type="list-item"
                      class="px-0"
                      height="19"
                      max-width="100"
                    >
                      {{ translatedTooltipMessage[notification.data.params.campaign_tooltip_id]?.description }}
                    </v-skeleton-loader>
                  </div>
                  <a
                    v-if="hasUncapturedCharge(notification.data)"
                    :href="helpUrls.payment_verification"
                    target="_blank"
                  >
                    {{ $t('notification_bell.invoice_details') }}
                  </a>
                </v-list-item-title>

                <v-list-item-action v-if="notification.data.model" class="mx-0 mb-0">
                  <view-link :notification-model="notification.data.model" />
                </v-list-item-action>
              </v-list-item-content>

              <button-read-notification
                v-model="notification.read_at"
                @input="readNotification(notification.id)"
              />
            </v-list-item>
          </v-list>

          <v-card-actions v-if="isShowBtn" class="d-flex justify-center">
            <a
              class="text-decoration-underline"
              @click="options.itemsPerPage = (options.page + 1) * options.itemsPerPage"
            >
              {{ $t('notification_bell.next_notification') }}
            </a>
          </v-card-actions>
        </v-card-text>

        <v-card-text v-else class="pa-0 d-flex align-center justify-center">
          <span class="pt-10">{{ $t('notification_bell.no_new_messages') }}</span>
        </v-card-text>
      </div>
      <div v-else>
        <v-skeleton-loader
          v-for="perPage in options.itemsPerPage"
          :key="perPage"
          class="mb-6"
          :elevation="0"
          type="list-item-avatar"
        />
      </div>
    </v-card>
  </v-card>
</template>

<script>
  import moment from 'moment'
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'
  import CSwitch from '@clickadilla/components/ui/CSwitch.vue'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { datatableFormater } from '@clickadilla/datatable-formatter'
  import { notificationsRepository } from '@/services/repository-factory.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import ViewLink from '@/views/Notification/NotificationBell/ViewLink.vue'
  import ButtonReadNotification from './ButtonReadNotification.vue'
  import RemoveNotificationDialog from './RemoveNotificationDialog.vue'
  import {
    modelTypes,
    notificationCategoryTypes,
    notificationEventTypes
  } from '@/views/Notification/NotificationBell/notificationt-types.js'
  import handleErrors from '@/services/handleErrors.js'

  const notificationCategoryGroupsType = {
    CAMPAIGN_APPROVE_STATUS: 'Campaign & Approve status',
    MONEY: 'Money',
    USER_DETAILS: 'User details',
    RECOMMENDATIONS: 'Recommendations'
  }

  export default {
    name: 'Notification',

    components: {
      CustomChipStatus,
      ViewLink,
      ButtonReadNotification,
      CToggleButtons,
      CSwitch,
      CSelect,
      RemoveNotificationDialog
    },
    data() {
      return {
        isShownRemove: false,
        tooltipTranslations: [],
        tooltipTranslationsAreLoading: false,
        options: {
          sortBy: ['created_at'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15,
          categoryGroups: null,
          readAtSwitch: true
        },
        headers: [
          {
            value: 'created_at',
            filters: true
          },
          {
            value: 'categoryGroupId',
            name: 'category_group_id',
            data: 'category_group_id',
            filters: true
          },
          {
            value: 'unreadOnly',
            name: 'unread_only',
            data: 'unread_only',
            filters: true
          }
        ]
      }
    },
    computed: {
      ...mapState('settings', ['notificationCategoryGroups', 'locale']),
      ...mapState('notification', [
        'notifications',
        'unreadNotifications',
        'recordsFiltered',
        'isLoading'
      ]),
      ...mapGetters('notification', ['approvalStatusTypes']),
      ...mapGetters('settings', ['helpUrls']),
      isShowBtn() {
        return (
          (this.options.readAtSwitch && this.notifications.length < this.unreadNotifications)
          || (!this.options.readAtSwitch && this.notifications.length < this.recordsFiltered)
        )
      },
      hasUncapturedCharge() {
        const uncapturedCharge = notificationEventTypes.INVOICE_SECURIONPAY_CHARGE_UNCAPTURED
        return (data) => data.event === uncapturedCharge && this.helpUrls.payment_verification
      },
      isMobile() {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
      },
      selectedIcon() {
        const foundIcon = this.buttonsCategoryGroups.find(
          (item) => item.value === this.options.categoryGroups
        )
        return foundIcon ? foundIcon.icon : ''
      },
      buttonsCategoryGroups() {
        const buttonsCategory = this.notificationCategoryGroups.map((category) => {
          const newCategory = {
            id: category.id,
            value: category.id,
            name: category.name
          }

          const icon = {
            [notificationCategoryGroupsType.CAMPAIGN_APPROVE_STATUS]: '$magnifier',
            [notificationCategoryGroupsType.MONEY]: '$money',
            [notificationCategoryGroupsType.USER_DETAILS]: '$user-details',
            [notificationCategoryGroupsType.RECOMMENDATIONS]: '$thumb-up'
          }[category.name] ?? ''

          return {
            ...newCategory,
            icon
          }
        })

        buttonsCategory.splice(0, 0, {
          id: buttonsCategory.length + 1,
          value: null,
          name: 'All',
          icon: '$all'
        })

        return buttonsCategory
      },
      getTranslatedModelType() {
        return (data) => ({
          [modelTypes.CAMPAIGN]: this.$t('notification_bell.campaign') || '-',
          [modelTypes.AD]: this.$t('notification_bell.ad') || '-'
        }[data.model && data.model.type])
      },
      getModelId() {
        return (data) => (data.model && data.model.id) || '-'
      },
      translatedTooltipMessage() {
        if (!this.tooltipTranslations.length) return ''

        return this.tooltipTranslations.reduce((acc, { id, translations }) => {
          acc[id] = translations.find(({ locale }) => locale === this.locale)
            || translations.find(({ locale }) => locale === 'en')
            || translations.find(({ locale }) => locale)
          return acc
        }, {})
      },
      compareNotificationIconByEvent() {
        return (categoryName) => {
          if (!categoryName) return '$magnifier'
          return {
            [notificationCategoryTypes.APPROVE_STATUS_CAMPAIGNS]: '$lightning',
            [notificationCategoryTypes.APPROVE_STATUS_AD]: '$lightning',
            [notificationCategoryTypes.CAMPAIGN_STATUS]: '$magnifier',
            [notificationCategoryTypes.REPLENISHMENT_OF_FUNDS]: '$magnifier',
            [notificationCategoryTypes.LIMITS]: '$magnifier',
            [notificationCategoryTypes.ACCOUNT]: '$user-details',
            [notificationCategoryTypes.BALANCE]: '$money',
            [notificationCategoryTypes.DEBIT]: '$money',
            [notificationCategoryTypes.TOOLTIPS]: '$targeting'
          }[categoryName]
        }
      },
      compareNotificationTextByEvent() {
        return (data) => ({
          [notificationEventTypes.CAMPAIGN_PAUSED_ACTIVATED]: this.$t(
            'notification_bell.paused_campaign_activated'
          ),
          [notificationEventTypes.INVOICE_SECURIONPAY_CHARGE_UNCAPTURED]: `
              ${this.$t('notification_bell.invoice_unsafe', {
          price: data?.params?.amount
          })}
            `,
          [notificationEventTypes.CAMPAIGN_ACTIVATED]: `${this.getTranslatedModelType(
            data
          )} #${this.getModelId(data)} ${this.$t('notification_bell.activated')}`,
          [notificationEventTypes.CAMPAIGN_APPROVED]: `${this.getTranslatedModelType(
            data
          )} #${this.getModelId(data)} ${this.$t('notification_bell.approved')}`,
          [notificationEventTypes.CAMPAIGN_REJECTED]: `${this.getTranslatedModelType(
            data
          )} #${this.getModelId(data)} ${this.$t('notification_bell.rejected')}`,
          [notificationEventTypes.CAMPAIGN_LIMITS_BELOW_MIN]: this.$t(
            'notification_bell.limits_below_minimal'
          ),
          [notificationEventTypes.CAMPAIGN_FIRST_NEVER_PAID]: this.$t(
            'notification_bell.first_never_paid'
          ),
          [notificationEventTypes.TRAFFIC_PROVIDERS_SUBSCRIPTION_PAYMENT]: this.$t('notification_bell.providers_unpaid', {
            days: data.params?.days_before_payment
          }),
          [notificationEventTypes.CAMPAIGN_UNPROFITABLE_PAUSED]: this.$t(
            'notification_bell.unprofitable_paused'
          ),
          [notificationEventTypes.CAMPAIGN_LOW_PRICE]: this.$t('notification_bell.campaign_low_price', {
            campaign: data.event.model?.id
          }),
          [notificationEventTypes.PAYMENT_CREATED]: this.$t('notification_bell.balance_topped', {
            amount: data.params?.amount,
            campaign: data?.params?.comment
          }),
          [notificationEventTypes.USER_LOW_BALANCE]: this.$t('notification_bell.low_balance'),
          [notificationEventTypes.USER_BALANCE_EXPIRING]: this.$t(
            'notification_bell.balance_expiring'
          ),
          [notificationEventTypes.USER_NO_CREDIT_PAYMENTS]: this.$t(
            'notification_bell.no_credit_payments'
          ),
          [notificationEventTypes.USER_NO_CAMPAIGN_CREATED]: this.$t(
            'notification_bell.no_campaign_created'
          ),
          [notificationEventTypes.USER_EMAIL_HAS_CHANGED]: this.$t(
            'notification_bell.email_has_changed'
          ),
          [notificationEventTypes.USER_FROZEN]: this.$t('notification_bell.frozen'),
          [notificationEventTypes.VERIFICATION_APPROVED]: `${this.$t(
            'notification_bell.verification'
          )} ${this.$t('notification_bell.approved')}`,
          [notificationEventTypes.VERIFICATION_CREATED]: `${this.$t(
            'notification_bell.verification'
          )} ${this.$t('notification_bell.created')}`,
          [notificationEventTypes.VERIFICATION_REJECTED]: `${this.$t(
            'notification_bell.verification'
          )} ${this.$t('notification_bell.rejected')}`,
          [notificationEventTypes.AD_APPROVED]: `${this.getTranslatedModelType(
            data
          )} #${this.getModelId(data)} ${this.$t('notification_bell.approved')}`,
          [notificationEventTypes.AD_REJECTED]: `${this.getTranslatedModelType(
            data
          )} #${this.getModelId(data)} ${this.$t('notification_bell.rejected')}`,
          [notificationEventTypes.APPROVABLE_NEEDS_EDITS]: `${this.getTranslatedModelType(
            data
          )} #${this.getModelId(data)} ${this.$t('notification_bell.needs_edits')}`
        }[data.event])
      }
    },
    watch: {
      options: {
        immediate: true,
        deep: true,
        handler() {
          this.fetchNotifications()
        }
      }
    },
    created() {
      this.fetchTooltipNotifications()
    },
    destroyed() {
      this.setNotifications([])
    },
    methods: {
      ...mapActions('notification', [
        'setUnreadNotifications',
        'setNotifications',
        'getNotifications',
        'read',
        'readAll',
        'remove'
      ]),
      resetOptions() {
        this.options.itemsPerPage = 15
        this.setNotifications([])
      },
      dateFormatter(date) {
        return moment(date).format('LL')
      },
      isShowDate(index) {
        return (
          index === 0
          || this.dateFormatter(this.notifications[index - 1].created_at)
            !== this.dateFormatter(this.notifications[index].created_at)
        )
      },
      isShowDivider(index) {
        return (
          index > 0
          && this.dateFormatter(this.notifications[index - 1].created_at)
            === this.dateFormatter(this.notifications[index].created_at)
        )
      },
      setReadAtSwitch(readAtSwitch) {
        this.options.readAtSwitch = readAtSwitch
        this.resetOptions()
      },
      isShowStatus(notification) {
        const categoryGroupName = notification?.category?.category_group?.name
        return categoryGroupName === notificationCategoryGroupsType.CAMPAIGN_APPROVE_STATUS
      },
      async fetchNotifications() {
        try {
          const params = datatableFormater({
            options: this.options,
            headers: this.headers,
            search: {
              categoryGroupId: this.options.categoryGroups,
              unreadOnly: this.options.readAtSwitch ? 1 : 0
            }
          })
          await this.getNotifications(params)
        } catch (error) {
          handleErrors(error)
        }
      },
      async fetchTooltipNotifications() {
        this.tooltipTranslationsAreLoading = true
        try {
          this.tooltipTranslations = await notificationsRepository.fetchTooltipsNotifications()
        } catch (error) {
          handleErrors(error)
        }
        this.tooltipTranslationsAreLoading = false
      },
      async readNotification(notificationId) {
        const params = datatableFormater({
          options: this.options,
          headers: this.headers,
          search: {
            categoryGroupId: this.options.categoryGroups,
            unreadOnly: this.options.readAtSwitch ? 1 : 0
          }
        })

        try {
          await this.read({
            notificationId,
            params
          })
        } catch (error) {
          handleErrors(error)
        }
      },
      async readAllNotification() {
        try {
          const params = datatableFormater({
            options: this.options,
            headers: this.headers,
            search: {
              categoryGroupId: this.options.categoryGroups,
              unreadOnly: this.options.readAtSwitch ? 1 : 0
            }
          })

          await this.readAll(params)
        } catch (error) {
          handleErrors(error)
        }
      },
      async removeNotifications() {
        try {
          await this.remove()
          this.isShownRemove = false
        } catch (error) {
          handleErrors(error)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep(.v-skeleton-loader__list-item) {
    height: auto;
    padding: 0;
  }
  :deep(.white-space) {
    white-space: unset;
  }
  .full-width {
    width: 100%;
  }
  .full-height {
    min-height: 100vh;
  }
  .isDisabled {
    color: var(--v-secondary-darken-base);
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
  }
</style>
